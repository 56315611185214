import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { debounce } from "../AppManager";
import { clearUser, generalURL } from "../Auth";

function Navbar({ slide, handleSlide }) {
  const [active, setActive] = useState("");
  const [flip, setFlip] = useState(false);

  const toggle = () => {
    setActive((old) => (old === "" ? "active" : ""));
  };

  useEffect(() => {
    !slide && setActive("");
  }, [slide]);

  useEffect(() => {
    const formtwo = document.getElementsByClassName("form-panel two")[0];

    function height() {
      const maxNav = formtwo.clientHeight - 80;
      const navbar = document.getElementById("navbar");
      if (navbar) navbar.style.maxHeight = maxNav + "px";
    }

    const debouncedHeight = debounce(height, 100);

    height();
    const resizeObserver = new ResizeObserver(debouncedHeight);
    resizeObserver.observe(formtwo);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(debouncedHeight.timeout);
    };
  }, []);

  return (
    <nav className="nav-sec" id="navbar">
      <div className={`prev-next ${active ? "hide" : ""}`}>
        <NavLink onClick={handleSlide} to={-1} title="Go Back">
          <img src="/images/icons/prev.png" alt="Go Back" />
        </NavLink>
        <NavLink onClick={handleSlide} to={+1} title="Next">
          <img src="/images/icons/next.png" alt="Next" />
        </NavLink>
      </div>
      <div className={`prev-next admin-link ${active ? "hide" : ""}`}>
        <a href={generalURL} target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/general.png" alt="General" />
        </a>
        <a
          href={process.env.REACT_APP_WORKERS}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/icons/workers.png" alt="Workers" />
        </a>
      </div>
      <div className={`menu ${active}`}>
        <div className="nav-btn trigger" onClick={toggle}>
          <span className="line"></span>
        </div>
        {!flip && (
          <div className="icons">
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Home"
                >
                  <span>
                    <img src="/images/icons/home.png" alt="Home" />
                  </span>
                  <label>Home</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/time"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Time"
                >
                  <span>
                    <img src="/images/icons/timer.png" alt="Time" />
                  </span>
                  <label>Time</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/session"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Session"
                >
                  <span>
                    <img src="/images/icons/session.png" alt="Session" />
                  </span>
                  <label>Session</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/upload"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Upload Students"
                >
                  <span>
                    <img src="/images/icons/upload.png" alt="Upload" />
                  </span>
                  <label>Upload</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/statistics"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Statistics"
                >
                  <span>
                    <img src="/images/icons/statistics.png" alt="Statistics" />
                  </span>
                  <label>Statistics</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/attendance"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Attendance"
                >
                  <span>
                    <img
                      src="/images/icons/folderfiles.png"
                      alt="Attendances"
                    />
                  </span>
                  <label>Attendances</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={() => {
                    clearUser();
                    handleSlide();
                  }}
                  to={"/login"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Logout"
                >
                  <span>
                    <img src="/images/icons/logout.png" alt="Logout" />
                  </span>
                  <label>Logout</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <span
                  onClick={() => {
                    setFlip(true);
                  }}
                  title="Switch"
                >
                  <span>
                    <img src="/images/icons/switchoff.png" alt="Switch" />
                  </span>
                  <label></label>
                </span>
              </div>
            </div>
          </div>
        )}
        {flip && (
          <div className="icons">
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/add"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Add Student"
                >
                  <span>
                    <img src="/images/icons/add.png" alt="Add" />
                  </span>
                  <label>Add</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/view"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="View Student"
                >
                  <span>
                    <img src="/images/icons/view.png" alt="View" />
                  </span>
                  <label>View</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/edit"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Edit Student"
                >
                  <span>
                    <img src="/images/icons/edit.png" alt="Edit" />
                  </span>
                  <label>Edit</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/delete"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Delete Student"
                >
                  <span>
                    <img src="/images/icons/delete.png" alt="Delete" />
                  </span>
                  <label>Delete</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/centres"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Centres"
                >
                  <span>
                    <img src="/images/icons/centres.png" alt="Centres" />
                  </span>
                  <label>Centres</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/departments"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Departments"
                >
                  <span>
                    <img
                      src="/images/icons/departments.png"
                      alt="Departments"
                    />
                  </span>
                  <label>Departments</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <NavLink
                  onClick={handleSlide}
                  to={"/passwords"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  title="Passwords"
                >
                  <span>
                    <img src="/images/icons/passwords.png" alt="Passwords" />
                  </span>
                  <label>Passwords</label>
                </NavLink>
              </div>
            </div>
            <div className="rotater">
              <div className="nav-btn nav-btn-icon">
                <span
                  onClick={() => {
                    setFlip(false);
                  }}
                  title="Switch"
                >
                  <span>
                    <img src="/images/icons/switchon.png" alt="Switch" />
                  </span>
                  <label></label>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
