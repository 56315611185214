import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { nanoid } from "nanoid";
import { LiaTimesCircle } from "react-icons/lia";
import {
  FaCheckDouble,
  FaCircleQuestion,
  FaMagnifyingGlass,
} from "react-icons/fa6";
import { debounce, filterObject, sortByProperty } from "../AppManager";

export default function DepartmentsSelection({
  setFormData,
  close,
  prevSelection,
}) {
  const [specialData, setSpecialData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [info, setInfo] = useState(false);
  const { departments } = useOutletContext();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (typeof prevSelection === "object" && prevSelection.length !== 0) {
      const prev = {};
      for (let k = 0; k < prevSelection.length; k++) {
        const selected = prevSelection[k];
        prev[selected] = true;
      }
      setSpecialData(prev);
    }
  }, [prevSelection]);

  useEffect(() => {
    const outlet = document.getElementsByClassName("outlet")[0];
    const selectionBox = document.getElementsByClassName("selection-box")[0];
    const specialSelect = document.getElementsByClassName("special-select")[0];
    const searchSpecial = document.getElementsByClassName(
      "form-group search-input"
    )[0];
    outlet.style.height = "75vh";

    function height() {
      const total = outlet.clientHeight - 80;
      const search = searchSpecial ? searchSpecial.clientHeight : 0;
      const selection = selectionBox ? selectionBox.clientHeight : 0;
      const left = total - search - selection;
      if (specialSelect) specialSelect.style.height = left + "px";
    }

    const debouncedHeight = debounce(height, 100);

    height();
    const resizeObserver = new ResizeObserver(debouncedHeight);
    resizeObserver.observe(outlet);
    selectionBox && resizeObserver.observe(selectionBox);
    resizeObserver.observe(specialSelect);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(debouncedHeight.timeout);
      outlet.style.removeProperty("height");
    };
  }, []);

  function handleSearch(e) {
    function cleanString(input) {
      // Regular expression to match only letters (both cases), numbers, and spaces
      const regex = /[^a-zA-Z0-9 ]/g;
      // Replace any character that is not a letter, number, or space with an empty string
      return input.replace(regex, "");
    }

    setSearch(cleanString(e.target.value));
  }

  function handleChange(e) {
    const { name, checked } = e.target;
    if (name === "All" || name === "All 2.0") {
      setDisabled(checked);
    }
    setSpecialData((old) => ({ ...old, [name]: checked }));
  }

  function specialSection({ department, level, code }) {
    const levels = [];
    for (let l = 100; l <= parseInt(level); l += 100) {
      department === "Level"
        ? levels.push(
            <span key={nanoid()}>
              <input
                type="checkbox"
                name={`${l}`}
                id={`${l}`}
                onChange={handleChange}
                checked={specialData[`${l}`]}
                disabled={disabled}
              />
              <label htmlFor={`${l}`}>{l}</label>
            </span>
          )
        : levels.push(
            <span key={nanoid()}>
              <input
                type="checkbox"
                name={`${code} - ${l}`}
                id={`${code} - ${l}`}
                onChange={handleChange}
                checked={specialData[`${code} - ${l}`]}
                disabled={disabled}
              />
              <label htmlFor={`${code} - ${l}`}>{l}</label>
            </span>
          );
    }

    return (
      <span className="special-select-section" key={nanoid()}>
        <strong>
          {department === "Level" ? department : `${department} - ${code}`}
        </strong>
        <span>{levels}</span>
      </span>
    );
  }

  const depts = [
    { programme: "Level", code: "", maxlevel: 600 },
    ...sortByProperty(departments, "programme"),
  ];

  const specialElement = depts
    .filter(
      ({ programme, maxlevel, code }) =>
        programme.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        maxlevel.toString().indexOf(search) !== -1 ||
        code.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
    .map((dept) =>
      specialSection({
        department: dept.programme,
        level: dept.maxlevel,
        code: dept.code,
      })
    );

  const closeAction = () => {
    const allCheck = specialData["All"] ? { All: true } : specialData;
    const select = filterObject(allCheck, (value) => value);
    const selections = Object.keys(select);
    const set = selections.length !== 0;
    setFormData((old) => ({
      ...old,
      departments: set ? selections : "",
      display: set ? selections.slice(0, 3).join(", ") : "",
    }));
    close();
  };

  const selectedElements = Object.keys(
    filterObject(specialData, (value) => value)
  )
    .sort()
    .map((select) => (
      <span key={nanoid()}>
        <span>{select}</span>
        <LiaTimesCircle
          onClick={() => {
            setSpecialData((old) => ({ ...old, [select]: false }));
          }}
        />
      </span>
    ));

  return (
    <div className="special-select-wrapper">
      <span className="special-close" onClick={closeAction}>
        <FaCheckDouble />
      </span>
      <div
        className="form-group search-input"
        style={{ marginBottom: "5px", marginTop: 0 }}
      >
        <FaMagnifyingGlass />
        <input
          type="text"
          id="key"
          name="key"
          required
          value={search}
          onChange={handleSearch}
          maxLength={20}
          placeholder="Search"
        />
      </div>
      <div
        className="selection-box"
        style={
          selectedElements.length !== 0
            ? {}
            : { height: 0, padding: 0, opacity: 0, pointerEvents: "none" }
        }
      >
        {selectedElements}
      </div>
      <div className="special-select">
        <span className="special-select-section">
          <strong>Special</strong>
          <span>
            <span>
              <input
                type="checkbox"
                name="All"
                id="All"
                onChange={handleChange}
                checked={specialData["All"]}
              />
              <label htmlFor="All">All</label>
            </span>
            <span>
              <input
                type="checkbox"
                name="All 2.0"
                id="All 2.0"
                onChange={handleChange}
                checked={specialData["All 2.0"]}
              />
              <label htmlFor="All 2.0">All 2.0</label>
            </span>
            <span>
              <input
                type="checkbox"
                name="Freshers"
                id="Freshers"
                onChange={handleChange}
                checked={specialData["Freshers"]}
              />
              <label htmlFor="Freshers">Freshers</label>
            </span>
            <span>
              <input
                type="checkbox"
                name="Direct Entry"
                id="Direct Entry"
                onChange={handleChange}
                checked={specialData["Direct Entry"]}
              />
              <label htmlFor="Direct Entry">Direct Entry</label>
            </span>
            <span>
              <input
                type="checkbox"
                name="Finalists"
                id="Finalists"
                onChange={handleChange}
                checked={specialData["Finalists"]}
              />
              <label htmlFor="Finalists">Finalists</label>
            </span>
            <span>
              <label>
                <FaCircleQuestion onClick={() => setInfo((old) => !old)} />
              </label>
            </span>
          </span>
        </span>
        {info && (
          <span
            className="special-select-section"
            style={{ position: "relative" }}
          >
            <LiaTimesCircle
              className="status-close"
              style={{ color: "inherit", fontSize: "1.1em", right: "5px" }}
              onClick={() => setInfo(false)}
            />
            <li>Freshers = 100 Level + Direct Entry</li>
            <li>
              Finalist = Only Final Years .e.g. 400lvl Engineering !== Finalist
            </li>
            <li>Others {">"} All</li>
            <li>All 2.0 {">"} Others</li>
            <li>Department Level {">"} Level</li>
          </span>
        )}
        {specialElement}
      </div>
    </div>
  );
}
