import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa6";
import { LiaCheckCircle, LiaExclamationTriangleSolid } from "react-icons/lia";
import { useNavigate, useSearchParams } from "react-router-dom";

function Offline({ close }) {
  const [transitionOnline, setTransitionOnline] = useState(false);
  const [connect, setConnect] = useState(false);
  const navigate = useNavigate();
  const next = useSearchParams()[0].get("next");

  function handleConnect(type) {
    setConnect(true);
    setTimeout(() => {
      setConnect(type);
      setTimeout(() => {
        setConnect(false);
      }, 2000);
    }, 5000);
  }

  useEffect(() => {
    function changeStatus() {
      handleConnect("success");
      setTimeout(() => {
        setTransitionOnline(true);
        setTimeout(() => {
          close();
        }, 2000);
      }, 5000);
    }
    if (navigator.onLine) {
      changeStatus();
    }
    window.addEventListener("online", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
    };
  }, [navigate, next, close]);

  const redbg = { backgroundColor: "red" };
  const greenbg = { backgroundColor: "green" };

  return (
    <section className="error offline">
      <img
        src={`/images/svg/${transitionOnline ? "online" : "offline"}.svg`}
        alt={transitionOnline ? "Online" : "Offline"}
        style={{ height: "170px" }}
      />
      <p>
        {connect
          ? connect === "success"
            ? "Restoring Page..."
            : "Attempting to Reconnect..."
          : "Please check your internet connection"}
      </p>
      <button
        className="error-btn"
        onClick={() => handleConnect("failed")}
        disabled={connect}
        style={
          connect === "failed" ? redbg : connect === "success" ? greenbg : null
        }
      >
        {connect ? (
          connect === "failed" ? (
            <span>
              <LiaExclamationTriangleSolid /> Failed
            </span>
          ) : connect === "success" ? (
            <span>
              <LiaCheckCircle /> Success
            </span>
          ) : (
            <span>
              <FaSpinner className="spinner" />
              Reconnecting...
            </span>
          )
        ) : (
          "Try Again"
        )}
      </button>
    </section>
  );
}

export default Offline;
