import React, { useEffect, useState } from "react";
import {
  FaCheckDouble,
  FaChevronDown,
  FaClock,
  FaEye,
  FaEyeSlash,
  FaPaperPlane,
  FaSpinner,
  FaTriangleExclamation,
} from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useOutletContext, useParams } from "react-router-dom";
import { passwordStrength } from "../AppManager";
import { changePassword, tokenRequest } from "../Auth";
import { nanoid } from "nanoid";

export default function Passwords() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [formData, setFormData] = useState({
    token: "",
    centre: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [showp, setShowp] = useState(false);
  const [mismatch, setMismatch] = React.useState(false);
  const [checkProgress, setCheckProgress] = React.useState({
    strength: "0%",
    color: "red",
    upper: false,
    lower: false,
    special: false,
    length: false,
    number: false,
  });
  const [centresArray, setCentresArray] = useState([]);
  const { centres } = useOutletContext();
  const { token } = useParams();

  useEffect(() => {
    if (token && token.length === 10) {
      setFormData((old) => ({ ...old, token }));
    }
  }, [token]);

  useEffect(() => {
    let cents = [];
    for (const cent in centres) {
      if (Object.hasOwnProperty.call(centres, cent)) {
        cents.push(cent);
      }
    }
    cents.sort();
    setCentresArray(cents);
  }, [centres]);

  const handleShowp = () => {
    setShowp((old) => !old);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldFormData) => {
      let newFormData = { ...oldFormData, [name]: value };

      setMismatch(
        newFormData.newpassword !== newFormData.confirmpassword &&
          newFormData.confirmpassword.length > 0
          ? true
          : false
      );
      setCheckProgress(passwordStrength(newFormData.newpassword));

      if (name === "centre") {
        setRequest(true);
      }

      return newFormData;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const reset = await changePassword(formData);
      setLoading(false);
      if (reset === "Success") {
        setStatus({
          status: "success",
          type: "success",
          message: "Password Reset Successful",
        });
        setFormData({
          confirmpassword: "",
          newpassword: "",
          centre: "",
          token: "",
        });
      } else if (reset === "Same") {
        setStatus({
          status: "Same",
          type: "failed",
          message: "New Password same as Old Password",
        });
      } else if (reset === "Mismatch") {
        setStatus({
          status: "Mismatch",
          type: "failed",
          message: "Password Mismatched",
        });
      } else if (reset === "Expired") {
        setStatus({
          status: "Expired",
          type: "failed",
          message: "Token Expired",
        });
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Password Reset Failed",
        });
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Password Reset Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  async function handleToken(e) {
    e.preventDefault();
    try {
      if (formData.centre) {
        setRequesting(true);
        const send = await tokenRequest({ centre: formData.centre });
        setRequesting(send);
        setTimeout(() => {
          setRequesting(false);
          setRequest(false);
        }, 2000);
      }
    } catch (error) {
      setRequesting("Failed");
      setTimeout(() => {
        setRequesting(false);
        setRequest(false);
      }, 2000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
    document.title = "Centres Password";
  }, [status]);

  const centresArrayElement = centresArray.map((centre) => (
    <option value={centre} key={nanoid()}>
      {centre}
    </option>
  ));

  const centresArrayElementWorkers = centresArray.map((centre) => (
    <option value={centre + " Workers"} key={nanoid()}>
      {centre + " Workers"}
    </option>
  ));

  return (
    <>
      <div className="form-header add-header">
        <h1>Set Password</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="centre">Worship Centre</label>
            <select
              disabled={loading || requesting}
              id="centre"
              onChange={handleChange}
              value={formData.centre}
              name="centre"
              required
              autoComplete="off"
            >
              <option disabled value="">
                Choose one
              </option>
              {centresArrayElement}
              {centresArrayElementWorkers}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          {formData.centre && request && (
            <div className="form-group">
              <p className="status-wrapper request-token" id="status-wrapper">
                <span className={`status-message`}>
                  Request Token?{" "}
                  <button
                    onClick={handleToken}
                    disabled={requesting}
                    style={{
                      backgroundColor: requesting
                        ? requesting === true
                          ? "gray"
                          : requesting === "Success"
                          ? "green"
                          : requesting === "Exists"
                          ? "orange"
                          : "red"
                        : "#072a46",
                    }}
                  >
                    {requesting ? (
                      requesting === true ? (
                        <FaSpinner className="spinner" />
                      ) : requesting === "Success" ? (
                        <FaCheckDouble />
                      ) : requesting === "Exists" ? (
                        <FaClock />
                      ) : (
                        <FaTriangleExclamation />
                      )
                    ) : (
                      <FaPaperPlane />
                    )}
                  </button>
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setRequest(false);
                  }}
                />
              </p>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="token">Token</label>
            <input
              disabled={loading}
              type="text"
              value={formData.token}
              onChange={handleChange}
              id="token"
              name="token"
              required
              autoComplete="off"
              style={{ letterSpacing: "10px" }}
              maxLength={10}
              placeholder="Token"
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="newpassword"
              style={mismatch ? { color: "#ff2200" } : undefined}
            >
              New Password
            </label>
            <input
              type={showp ? "text" : "password"}
              name="newpassword"
              id="newpassword"
              disabled={loading}
              onChange={handleChange}
              value={formData.newpassword}
              placeholder="New Password"
              required
              autoComplete="off"
              className="password-input"
              maxLength={20}
            />
            {!showp && <FaEye onClick={handleShowp} className="password-eye" />}
            {showp && (
              <FaEyeSlash onClick={handleShowp} className="password-eye" />
            )}
          </div>
          <div className="form-group">
            <label
              htmlFor="confirmpassword"
              style={mismatch ? { color: "#ff2200" } : undefined}
            >
              Confirm Password
            </label>
            <input
              type={showp ? "text" : "password"}
              name="confirmpassword"
              id="confirmpassword"
              disabled={loading}
              onChange={handleChange}
              value={formData.confirmpassword}
              required
              autoComplete="off"
              placeholder="Confirm Password"
              className="password-input"
              maxLength={20}
            />
            {!showp && <FaEye onClick={handleShowp} className="password-eye" />}
            {showp && (
              <FaEyeSlash onClick={handleShowp} className="password-eye" />
            )}
          </div>
          <div className="form-group">
            <span className="password-note">
              <div id="strength">
                <div
                  id="strength-bar"
                  style={{
                    width: checkProgress.strength,
                    backgroundColor: checkProgress.color,
                  }}
                ></div>
              </div>
              <i>
                At Least:
                <ul>
                  <li className={checkProgress.upper ? "complete" : null}>
                    <span> 1 Upper Case</span>
                  </li>
                  <li className={checkProgress.lower ? "complete" : null}>
                    <span> 1 Lower Case</span>
                  </li>
                  <li className={checkProgress.number ? "complete" : null}>
                    <span> 1 Number</span>
                  </li>
                  <li className={checkProgress.special ? "complete" : null}>
                    <span> 1 Special Character</span>
                  </li>
                  <li className={checkProgress.length ? "complete" : null}>
                    <span> 6 Characters</span>
                  </li>
                </ul>
              </i>
            </span>
          </div>
          <div className="form-group">
            <button
              type="submit"
              disabled={
                loading ||
                mismatch ||
                (checkProgress.strength !== "0%" &&
                  checkProgress.strength !== "100%")
              }
            >
              {loading ? <FaSpinner className="spinner" /> : "Set"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
