import React, { useEffect, useState } from "react";
import { FaChevronDown, FaClock, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import CentresSelection from "./CentresSelection";
import { eventTime } from "../AppManager";

export default function Time() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    display: "",
    centres: "",
    start: "",
    end: "",
    active: "",
  });
  const [showp, setShowp] = useState(false);

  function timeCompare(start, end) {
    const startArray = start.split(":").map((a) => parseInt(a));
    const endArray = end.split(":").map((a) => parseInt(a));

    if (startArray[0] > endArray[0]) {
      return false;
    } else if (startArray[0] < endArray[0]) {
      return true;
    } else {
      if (startArray[1] < endArray[1]) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleShowp = () => {
    setShowp((old) => !old);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({ ...oldData, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      if (!timeCompare(formData.start, formData.end)) {
        setStatus({
          status: "error",
          type: "failed",
          message: "Start Time can't be End Time",
        });
        setLoading(false);
        return;
      }

      const set = await eventTime({
        ...formData,
        centres: JSON.stringify(formData.centres),
      });
      setTimeout(() => {
        if (set === "Success") {
          setStatus({
            status: "success",
            type: "success",
            message: "Event Time Set",
          });
          setFormData({
            display: "",
            centres: "",
            start: "",
            end: "",
            active: "",
          });
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Update Failed",
          });
        }
        setLoading(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
    document.title = "Event Time";
  }, [status]);

  return (
    <>
      {showp && (
        <CentresSelection
          setFormData={setFormData}
          close={() => {
            setShowp(false);
          }}
          prevSelection={formData.departments}
        />
      )}
      <div className="form-header add-header">
        <h1>Event Time</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="display">Worship Centre</label>
            <input
              type="text"
              placeholder="Worship Centre"
              id="display"
              value={formData.display}
              name="display"
              onFocus={loading ? null : handleShowp}
              disabled={loading}
              autoComplete="off"
              required
              style={{ cursor: "default" }}
            />
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="start">Start</label>
            <input
              type="time"
              disabled={loading}
              id="start"
              onChange={handleChange}
              value={formData.start}
              name="start"
              required
              onClick={(e) => e.target.showPicker()}
            />
            <FaClock className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="end">End</label>
            <input
              type="time"
              disabled={loading}
              id="end"
              onChange={handleChange}
              value={formData.end}
              name="end"
              required
              onClick={(e) => e.target.showPicker()}
            />
            <FaClock className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="active">Active</label>
            <select
              disabled={loading}
              id="active"
              onChange={handleChange}
              value={formData.active}
              name="active"
              required
              autoComplete="off"
            >
              <option disabled value="">
                Choose one
              </option>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Set"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
