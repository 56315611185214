import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useParams } from "react-router-dom";
import { passwordStrength, showSwal } from "../AppManager";
import { passwordReset, verifyToken } from "../Auth";

export default function Reset() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    token: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [showp, setShowp] = useState(false);
  const [mismatch, setMismatch] = React.useState(false);
  const [checkProgress, setCheckProgress] = React.useState({
    strength: "0%",
    color: "red",
    upper: false,
    lower: false,
    special: false,
    length: false,
    number: false,
  });
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && token.length === 10) {
      setFormData((old) => ({ ...old, token }));
    } else {
      showSwal("error", "Invalid Token");
      navigate("/login", { replace: true });
    }

    verifyToken(token)
      .then((res) => {
        if (!res) {
          setFormData((old) => ({ ...old, token: "" }));
          showSwal("error", "Invalid Token");
          navigate("/login", { replace: true });
        }
      })
      .catch((err) => {
        setFormData((old) => ({ ...old, token: "" }));
        showSwal("error", "Invalid Token");
        navigate("/login", { replace: true });
      });
    document.title = "Reset Password";
  }, [navigate, token]);

  const handleShowp = () => {
    setShowp((old) => !old);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldFormData) => {
      let newFormData = { ...oldFormData, [name]: value };

      setMismatch(
        newFormData.newpassword !== newFormData.confirmpassword &&
          newFormData.confirmpassword.length > 0
          ? true
          : false
      );
      setCheckProgress(passwordStrength(newFormData.newpassword));

      return newFormData;
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      if (token !== formData.token) {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "failed",
            message: "Incorrect Token",
          });
          setLoading(false);
        }, 1000);
      }
      const reset = await passwordReset(formData);
      setLoading(false);
      if (reset === "Success") {
        setLoading(true);
        setStatus({
          status: "success",
          type: "success",
          message: "Password Reset Successful. Redirecting...",
        });
        setFormData({ confirmpassword: "", newpassword: "", ...formData });
        setTimeout(() => {
          navigate("/login?message=Login with New Password", { replace: true });
        }, 1000);
      } else if (reset === "Same") {
        setStatus({
          status: "Same",
          type: "failed",
          message: "New Password same as Old Password",
        });
      } else if (reset === "Mismatch") {
        setStatus({
          status: "Mismatch",
          type: "failed",
          message: "Password Mismatched",
        });
      } else if (reset === "Expired") {
        setStatus({
          status: "Expired",
          type: "failed",
          message: "Token Expired",
        });
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Password Reset Failed",
        });
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Password Reset Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  return (
    <>
      <div className="form-header add-header">
        <h1>Password Reset</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="token">Token</label>
            <input
              disabled={loading}
              type="text"
              value={formData.token}
              id="token"
              name="token"
              required
              readOnly
              style={{ letterSpacing: "10px", pointerEvents: "none" }}
              maxLength={10}
            />
          </div>
          <div className="form-group">
            <label
              htmlFor="newpassword"
              style={mismatch ? { color: "#ff2200" } : undefined}
            >
              New Password
            </label>
            <input
              type={showp ? "text" : "password"}
              name="newpassword"
              id="newpassword"
              disabled={loading}
              onChange={handleChange}
              value={formData.newpassword}
              placeholder="New Password"
              required
              className="password-input"
              maxLength={20}
            />
            {!showp && <FaEye onClick={handleShowp} className="password-eye" />}
            {showp && (
              <FaEyeSlash onClick={handleShowp} className="password-eye" />
            )}
          </div>
          <div className="form-group">
            <label
              htmlFor="confirmpassword"
              style={mismatch ? { color: "#ff2200" } : undefined}
            >
              Confirm Password
            </label>
            <input
              type={showp ? "text" : "password"}
              name="confirmpassword"
              id="confirmpassword"
              disabled={loading}
              onChange={handleChange}
              value={formData.confirmpassword}
              required
              placeholder="Confirm Password"
              className="password-input"
              maxLength={20}
            />
            {!showp && <FaEye onClick={handleShowp} className="password-eye" />}
            {showp && (
              <FaEyeSlash onClick={handleShowp} className="password-eye" />
            )}
          </div>
          <div className="form-group">
            <span className="password-note">
              <div id="strength">
                <div
                  id="strength-bar"
                  style={{
                    width: checkProgress.strength,
                    backgroundColor: checkProgress.color,
                  }}
                ></div>
              </div>
              <i>
                At Least:
                <ul>
                  <li className={checkProgress.upper ? "complete" : null}>
                    <span> 1 Upper Case</span>
                  </li>
                  <li className={checkProgress.lower ? "complete" : null}>
                    <span> 1 Lower Case</span>
                  </li>
                  <li className={checkProgress.number ? "complete" : null}>
                    <span> 1 Number</span>
                  </li>
                  <li className={checkProgress.special ? "complete" : null}>
                    <span> 1 Special Character</span>
                  </li>
                  <li className={checkProgress.length ? "complete" : null}>
                    <span> 6 Characters</span>
                  </li>
                </ul>
              </i>
            </span>
          </div>
          <div className="form-group">
            <button
              type="submit"
              disabled={
                loading ||
                mismatch ||
                (checkProgress.strength !== "0%" &&
                  checkProgress.strength !== "100%")
              }
            >
              {loading ? <FaSpinner className="spinner" /> : "Reset"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
