import React, { useEffect, useState } from "react";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import { cleanName } from "../AppManager";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { nanoid } from "nanoid";

export default function Search() {
  const params = useSearchParams()[0];
  const next = params.get("next");
  const [formData, setFormData] = useState({
    name: "",
    department: "",
    level: "",
  });
  const [loading, setLoading] = useState(false);
  const [maxLevel, setMaxLevel] = useState(600);
  const { departments } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    !next && navigate("/");
  }, [navigate, next]);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((oldData) => ({
      ...oldData,
      [name]: name === "name" ? cleanName(value) : value,
    }));
    if (name === "department") {
      if (value) {
        const selected = departments.filter((dept) => dept.programme === value);
        setMaxLevel(selected[0].maxlevel);
      } else {
        setMaxLevel(600);
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    navigate(`result?next=${next}`, { state: { ...formData } });
  }

  const departmentElement = departments.map((dept) => (
    <option value={dept.programme} key={nanoid()}>
      {dept.programme}
    </option>
  ));

  const levelElements = [];
  if (maxLevel !== 0) {
    for (let i = 100; i <= maxLevel; i += 100) {
      levelElements.push(
        <option value={i} key={nanoid()}>
          {i}
        </option>
      );
    }
  }

  document.title = "Student Search";

  return (
    <>
      <div className="form-header add-header">
        <h1>Student Search</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.name}
              minLength={3}
              maxLength={30}
              required
              id="name"
              name="name"
              placeholder="Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="department">Department</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.department}
              name="department"
              id="department"
            >
              <option value="">Choose one...</option>
              {departmentElement}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="level">Level</label>
            <select
              disabled={loading}
              onChange={handleChange}
              value={formData.level}
              id="level"
              name="level"
            >
              <option value="">Choose one...</option>
              {levelElements}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Search"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
