import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass, FaSpinner } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { debounce, getMonths } from "../AppManager";
import { nanoid } from "nanoid";

export default function AttendanceList() {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  function cleanString(input) {
    // Regular expression to match only letters (both cases), numbers, and spaces
    const regex = /[^a-zA-Z0-9 ]/g;
    // Replace any character that is not a letter, number, or space with an empty string
    return input.replace(regex, "");
  }

  function handleSearch(e) {
    setSearch(cleanString(e.target.value));
  }

  useEffect(() => {
    getMonths()
      .then((data) => {
        setList(data);
        setLoading(false);
      })
      .catch((err) => err);
    document.title = "Attendances";
  }, []);

  useEffect(() => {
    const container = document.querySelector(".form-content");

    function width() {
      const width = 58;
      const contain = container.clientWidth;
      const allowed = Math.floor(contain / width);
      const max = width * allowed;
      const wrapper = document.querySelector(".attendances-wrapper");
      if (wrapper) wrapper.style.width = max + 3 + "px";
    }

    const debouncedWidth = debounce(width, 100);

    width();
    const resizeObserver = new ResizeObserver(debouncedWidth);
    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(debouncedWidth.timeout);
    };
  }, []);

  const listElements = list
    .filter((month) => month.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    .map((month) => (
      <Link to={month.toLowerCase()} key={nanoid()}>
        <img src="/images/icons/folderfile.png" alt={month} />
        <label>{month}</label>
      </Link>
    ));

  return (
    <>
      <div className="form-header">
        <h1>Attendances</h1>
      </div>
      <div className="form-content">
        {list.length > 10 && (
          <div className="form-group search-input">
            <FaMagnifyingGlass />
            <input
              type="text"
              id="key"
              name="key"
              required
              value={search}
              onChange={handleSearch}
              maxLength={8}
              placeholder="Attendance Search"
            />
          </div>
        )}
        <form className="attendances-wrapper">
          {loading ? (
            <span>
              <img
                src="/images/icons/folderloading.png"
                alt="Fetching Records"
              />
              <label>
                <FaSpinner className="spinner" />
              </label>
            </span>
          ) : (
            <>
              {listElements.length > 1 && (
                <Link to={"average"} key={nanoid()}>
                  <img src="/images/icons/average.png" alt={"Average"} />
                  <label>Average</label>
                </Link>
              )}
              {listElements}
            </>
          )}
          {!loading && list.length === 0 && (
            <span>
              <img src="/images/icons/folderempty.png" alt="No Record" />
              <label>No Record</label>
            </span>
          )}
        </form>
      </div>
    </>
  );
}
