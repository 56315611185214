import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  cleanMatric,
  getNowStudent,
  getStudent,
  showSwal,
} from "../AppManager";
import { FaSearch } from "react-icons/fa";

export default function MatricInput() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ matric: "" });
  const [heading, setHeading] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { month } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => ({ ...old, [name]: cleanMatric(value) }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const res = month
        ? await getStudent(month, formData.matric)
        : await getNowStudent(formData.matric);
      if (typeof res === "object" && res.status === "Success") {
        const member = res;
        navigate(formData.matric, {
          state: { permission: true, id: formData.matric, member },
        });
      } else {
        showSwal("error", "Student not Found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSwal("error", "Student not Found");
    }
  }

  useEffect(() => {
    const pages = ["view", "delete", "edit"];
    const pageHead = {
      home: {
        title: "View Student",
      },
      view: {
        title: "View Student",
      },
      edit: {
        title: "Edit Student",
      },
      delete: {
        title: "Delete Student",
      },
    };
    let currPage = "home";
    for (let i = 0; i < pages.length; i++) {
      const element = pages[i];
      if (pathname === "/") {
        currPage = "home";
        break;
      } else if (
        pathname === `/${element}` ||
        pathname.search(`/${element}/`) > -1
      ) {
        currPage = element;
        break;
      }
    }
    const heading = pageHead[currPage].title;
    setHeading(heading);
    document.title = heading;
  }, [pathname]);

  return (
    <>
      <div className="form-header">
        <h1>{heading}</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="matric">Matric Number</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.matric}
              name="matric"
              id="matric"
              className="matric-input"
              required
              placeholder="Matric Number"
              pattern="\d{4,5}|[0-9]{5}[A-Za-z]{2}"
              minLength={4}
              maxLength={7}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              Submit
            </button>
          </div>
          <div className="special-link">
            <Link to={`/search?next=${pathname}`}>
              <FaSearch /> Student Search
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}
