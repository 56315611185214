import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Layout from "./components/Layout";
import Error from "./components/Error";
import Login from "./pages/Login";
import NotFound from "./components/NotFound";
import Add from "./pages/Add";
import MatricInput from "./pages/MatricInput";
import View from "./pages/View";
import Edit from "./pages/Edit";
import Delete from "./pages/Delete";
import AttendanceList from "./pages/AttendanceList";
import Attendance from "./pages/Attendance";
import { authentication } from "./Auth";
import Reset from "./pages/Reset";
import CentreDepartments from "./pages/CentreDepartments";
import Passwords from "./pages/Passwords";
import Upload from "./pages/Upload";
import Session from "./pages/Session";
import Statistics from "./pages/Statistics";
import Departments from "./pages/Departments";
import DeleteDepartment from "./pages/DeleteDepartment";
import EditDepartment from "./pages/EditDepartment";
import AddDepartment from "./pages/AddDepartment";
import Centres from "./pages/Centres";
import DeleteCentre from "./pages/DeleteCentre";
import AddCentre from "./pages/AddCentre";
import AttendanceAverageInput from "./pages/AttendanceAverageInput";
import AttendanceAverage from "./pages/AttendanceAverage";
import Time from "./pages/Time";
import Search from "./pages/Search";
import SearchResult from "./pages/SearchResult";

async function loader({ request }) {
  const { pathname } = new URL(request.url);
  if (pathname.indexOf("/login") !== -1 || pathname.indexOf("/reset") !== -1) {
    return null;
  }
  try {
    const admin = await authentication();
    if (!admin) {
      if (!document.referrer && pathname === "/") {
        return redirect(`/login?next=${pathname}`);
      }
      return redirect(`/login?message=Login Required&next=${pathname}`);
    }
  } catch (error) {
    return redirect("/login?message=Error Occurred, Login");
  }
  return null;
}

const transition = { duration: 0.5 };

const AnimatedRoute = ({ children }) => (
  <motion.div
    key={window.location.pathname}
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 100 }}
    transition={transition}
  >
    {children}
  </motion.div>
);

export const routes = [
  { path: "/", element: <CentreDepartments />, errorElement: <Error /> },
  { path: "login", element: <Login /> },
  { path: "reset/:token", element: <Reset /> },
  { path: "add", element: <Add /> },
  { path: "view/:matric", element: <View /> },
  { path: "view", element: <MatricInput /> },
  { path: "edit/:matric", element: <Edit /> },
  { path: "edit", element: <MatricInput /> },
  { path: "delete/:matric", element: <Delete /> },
  { path: "delete", element: <MatricInput /> },
  { path: "search", element: <Search /> },
  { path: "search/result", element: <SearchResult /> },
  { path: "upload", element: <Upload /> },
  { path: "session", element: <Session /> },
  { path: "departments/add", element: <AddDepartment /> },
  { path: "departments/edit/:code", element: <EditDepartment /> },
  { path: "departments/delete/:code", element: <DeleteDepartment /> },
  { path: "departments", element: <Departments /> },
  { path: "centres/add", element: <AddCentre /> },
  { path: "centres/delete/:centre", element: <DeleteCentre /> },
  { path: "centres", element: <Centres /> },
  { path: "passwords/:token", element: <Passwords /> },
  { path: "passwords", element: <Passwords /> },
  { path: "attendance", element: <AttendanceList /> },
  { path: "attendance/average/:range/:matric", element: <View /> },
  { path: "attendance/average/:range", element: <AttendanceAverage /> },
  { path: "attendance/average", element: <AttendanceAverageInput /> },
  { path: "attendance/:month/view/:matric", element: <View /> },
  { path: "attendance/:month/view", element: <MatricInput /> },
  { path: "attendance/:month", element: <Attendance /> },
  { path: "statistics", element: <Statistics /> },
  { path: "time", element: <Time /> },
  { path: "*", element: <NotFound /> },
];

const routerElements = {
  path: "/",
  element: <Layout />,
  children: routes.map((route) =>
    route.childOf
      ? {}
      : {
          index: route.path === "/",
          path: route.path === "/" ? undefined : route.path,
          element: <AnimatedRoute>{route.element}</AnimatedRoute>,
          loader: route.loader ? route.loader : loader,
          action: route.action ? route.action : undefined,
          errorElement: route.errorElement ? route.errorElement : <Error />,
          children:
            route.path === "/"
              ? undefined
              : routes.map((rout) => {
                  if (rout.childOf === route.parent) {
                    return {
                      index: rout.path === "/",
                      path: rout.path === "/" ? undefined : rout.path,
                      element: <AnimatedRoute>{rout.element}</AnimatedRoute>,
                      loader: rout.loader ? rout.loader : loader,
                      action: rout.action ? rout.action : undefined,
                      errorElement: rout.errorElement ? (
                        rout.errorElement
                      ) : (
                        <Error />
                      ),
                      children:
                        rout.path === "/"
                          ? undefined
                          : routes.map((routs) => {
                              if (routs.childOf === rout.parent) {
                                return {
                                  index: routs.path === "/",
                                  path:
                                    routs.path === "/" ? undefined : routs.path,
                                  element: (
                                    <AnimatedRoute>
                                      {routs.element}
                                    </AnimatedRoute>
                                  ),
                                  loader: routs.loader ? routs.loader : loader,
                                  action: routs.action
                                    ? routs.action
                                    : undefined,
                                  errorElement: routs.errorElement ? (
                                    routs.errorElement
                                  ) : (
                                    <Error />
                                  ),
                                };
                              } else {
                                return {};
                              }
                            }),
                    };
                  } else {
                    return {};
                  }
                }),
        }
  ),
};

const router = createBrowserRouter([routerElements]);

export default function App() {
  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  );
}
