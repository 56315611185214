import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  cleanMatric,
  generateAverageAttendance,
  getStudentAverage,
  showSwal,
  tableExists,
} from "../AppManager";
import { downloadBaseURL } from "../Auth";
import { FaSpinner } from "react-icons/fa6";

export default function AttendanceAverage() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ matric: "" });
  const [download, setDownload] = useState({ state: "", link: "" });
  const navigate = useNavigate();
  const { range } = useParams();

  const table = range.replace(/ /g, "").replace("-", "").toLowerCase();

  document.title = range + " Attendance";

  useEffect(() => {
    tableExists(table)
      .then((res) => {
        !res && navigate("/attendance/average");
      })
      .catch((err) => {
        navigate("/attendance/average");
      });
  }, [table, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => ({ ...old, [name]: cleanMatric(value) }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await getStudentAverage(table, formData.matric);

      if (typeof res === "object" && res.status === "Success") {
        const member = res;
        navigate(formData.matric, {
          state: { permission: true, id: formData.matric, member },
        });
      } else {
        showSwal("error", "Student not Found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSwal("error", "Student not Found");
    }
  }

  function downloadAttendance() {
    setDownload({ state: "generating", link: "" });
    generateAverageAttendance(table, range)
      .then((filename) => {
        if (filename === "Empty") {
          showSwal("error", "Attendance Download Failed", "No Record Found");
          setDownload({ state: "", link: "" });
        } else if (filename === "Failed") {
          showSwal("error", "Attendance Download Failed");
          setDownload({ state: "", link: "" });
        } else if (filename.indexOf(".xlsx") !== -1) {
          setTimeout(() => {
            setDownload({
              state: "ready",
              link: `${downloadBaseURL}${filename}`,
            });
          }, 1000);
        } else {
          showSwal("error", "Attendance Download Failed");
          setDownload({ state: "", link: "" });
        }
      })
      .catch((err) => {
        showSwal("error", "Attendance Download Failed");
        setDownload({ state: "", link: "" });
      });
  }

  return (
    <>
      <div className="form-header">
        <h1>{range} Average Attendance</h1>
      </div>
      <div className="form-content">
        <div style={{ display: "flex", justifyContent: "center" }}>
          {download.state === "ready" ? (
            <a
              download
              href={download.link}
              onClick={() => {
                setDownload({ state: "", link: "" });
              }}
              className={`attendance-download ${
                download.state === "ready" ? "active" : ""
              }`}
            >
              <img src="/images/excel.png" alt="File Icon" />
              <label>Download</label>
            </a>
          ) : (
            <span
              className="attendance-download"
              onClick={download.state === "" ? downloadAttendance : undefined}
            >
              <img src="/images/excel.png" alt="File Icon" />
              <label style={{ width: "100%" }}>
                {download.state === "" ? (
                  `Attendance`
                ) : (
                  <FaSpinner
                    style={{ fontSize: "1.2em" }}
                    className="spinner"
                  />
                )}
              </label>
            </span>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="matric">Matric Number</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.matric}
              name="matric"
              id="matric"
              className="matric-input"
              required
              placeholder="Matric Number"
              pattern="\d{4,5}|[0-9]{5}[A-Za-z]{2}"
              minLength={4}
              maxLength={7}
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
