import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FaCaretRight, FaEye, FaSearch, FaSpinner } from "react-icons/fa";
import { nanoid } from "nanoid";
import { generalURL } from "../Auth";
import { studentSearch } from "../AppManager";
import { LiaTimesCircle } from "react-icons/lia";

export default function SearchResult() {
  const params = useSearchParams()[0];
  const next = params.get("next");
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    name: "",
    department: "",
    level: "",
  });
  const [results, setResults] = useState([]);
  const [member, setMember] = useState({
    name: "",
    matric: "",
    department: "",
    level: "",
    image: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state && state.name) {
      const { name, department, level } = state;
      setSearch({ name, department, level });
      studentSearch({ name, department, level })
        .then((data) => setResults(data))
        .catch((err) => err)
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/");
    }
  }, [navigate, state]);

  document.title = "Search Result";

  const resultsElement = results.map((student) => (
    <div key={nanoid()}>
      <span>{student.name}</span>
      <Link
        onClick={(e) => {
          e.preventDefault();
          setMember(student);
        }}
      >
        <FaEye />
      </Link>
      <Link
        to={`${next}/${student.idnum}`}
        state={{ permission: true, id: student.idnum, member: student }}
      >
        <FaCaretRight />
      </Link>
    </div>
  ));

  const len = results.length;

  return (
    <>
      <div className="form-header add-header">
        <h1>
          Search Result{len > 1 && "s"} ({len})
        </h1>
      </div>
      <div
        className="form-content"
        style={{ marginTop: "-20px", color: "#072a46" }}
      >
        <form>
          <div>
            <span>
              <strong>Name: </strong>
              {search.name}
            </span>
            <br />
            {search.department && (
              <span>
                <strong>Department: </strong>
                {search.department}
                <br />
              </span>
            )}
            {search.level && (
              <span>
                <strong>Level: </strong>
                {search.level}
              </span>
            )}
          </div>
          {member.name && (
            <div className="search-special-box">
              <div className="edit-group">
                <LiaTimesCircle
                  className="status-close"
                  style={{ color: "#072a46", fontSize: "1.1em" }}
                  onClick={() => setMember({})}
                />
                <div>
                  <h5 className="text-center fw-bolder">Student Details</h5>
                </div>
                <div className="edit-details">
                  <div className="imgwrap">
                    <img
                      onError={(e) => {
                        if (e.target.src !== "/user.png") {
                          e.target.onerror = null;
                          e.target.src = "/user.png";
                        }
                      }}
                      className="img"
                      src={`${generalURL}/images/students/${member.image}`}
                      alt={member.name}
                    />
                  </div>
                  <div className="edit-details-text">
                    <p>{member.name}</p>
                    <p>{member.matric}</p>
                    <p>{member.department}</p>
                    <p>{member.level} Level</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="departments-wrapper">
            {loading && (
              <div>
                <span>
                  <FaSpinner
                    className="spinner"
                    style={{ marginTop: "-3px" }}
                  />{" "}
                  Searching...
                </span>
              </div>
            )}
            {resultsElement}
            {!loading && resultsElement.length === 0 && (
              <div>
                <span>No Student Found</span>
                <Link to={`/search`}>
                  <FaSearch />
                </Link>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
