import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { LiaTimesCircle } from "react-icons/lia";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import DepartmentsSelection from "./DepartmentsSelection";
import { useOutletContext } from "react-router-dom";
import { updateCentreDepartments } from "../AppManager";

export default function CenterDepartments() {
  const [formData, setFormData] = useState({
    centre: "",
    departments: "",
    display: "",
  });
  const [status, setStatus] = React.useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [showp, setShowp] = useState(false);
  const [centresArray, setCentresArray] = useState([]);
  const { centres } = useOutletContext();

  useEffect(() => {
    let cents = [];
    for (const cent in centres) {
      if (Object.hasOwnProperty.call(centres, cent)) {
        cents.push(cent);
      }
    }
    cents.sort();
    setCentresArray(cents);
    document.title = "Centre Departments";
  }, [centres]);

  const handleShowp = () => {
    setShowp((old) => !old);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => ({
      ...old,
      [name]: value,
      departments:
        name === "centre" ? JSON.parse(centres[value]) : old.departments,
      display:
        name === "centre"
          ? JSON.parse(centres[value]).slice(0, 3).join(", ")
          : old.display,
    }));
  };

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setStatus({ status: "", type: "", message: "" });
      setLoading(true);
      const data = {
        ...formData,
        departments: JSON.stringify(formData.departments),
      };
      const update = await updateCentreDepartments(data);
      setTimeout(() => {
        if (update === "Updated") {
          setStatus({
            status: "success",
            type: "success",
            message: "Updated Successfully",
          });
          setFormData({
            centre: "",
            departments: "",
            display: "",
          });
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Update Failed",
          });
        }
        setLoading(false);
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  const centresArrayElement = centresArray.map((centre) => (
    <option value={centre} key={nanoid()}>
      {centre}
    </option>
  ));

  return (
    <>
      {showp && (
        <DepartmentsSelection
          setFormData={setFormData}
          close={() => {
            setShowp(false);
          }}
          prevSelection={formData.departments}
        />
      )}
      <div className="form-header">
        <h1>Centre Departments</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="centre">Worship Centre</label>
            <select
              disabled={loading}
              id="centre"
              onChange={handleChange}
              value={formData.centre}
              name="centre"
              required
            >
              <option disabled value="">
                Choose one
              </option>
              {centresArrayElement}
            </select>
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <label htmlFor="display">Departments</label>
            <input
              type="text"
              placeholder="Departments"
              id="display"
              value={formData.display}
              name="display"
              onFocus={loading ? null : handleShowp}
              disabled={loading}
              autoComplete="off"
              required
              style={{ cursor: "default" }}
            />
            <FaChevronDown className="select-icon" />
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
