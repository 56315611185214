import React from 'react';

function Footer() {
    return (
        <footer className='w-100 h-5 position-absolute bottom-0 text-center z-3'>
            <p>© Copyright RUN Chapel of Power {new Date().getFullYear()}. All Right Reserved. Designed and Developed by <strong><a href='https://taiwojoshua.netlify.app/' target='_blank' rel='noreferrer'>Taiwo Joshua</a></strong></p>
        </footer>
    );
}

export default Footer;