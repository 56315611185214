import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { extractIdnum, studentsUpload } from "../AppManager";
import { FaCheckDouble, FaCopy, FaEye, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";

function checkExtension(string) {
  const extensions = ["xls", "xlsx"];
  const ext = string.slice(string.lastIndexOf(".") + 1).toLowerCase();
  if (extensions.includes(ext)) {
    return true;
  }
  return false;
}

function checkFile(file, maxSize) {
  const maximum = 1024 * maxSize;
  if (file.size <= maximum && checkExtension(file.name)) {
    return true;
  } else {
    if (file.size > maximum) {
      return "size";
    } else if (!checkExtension(file.name)) {
      return "format";
    }
  }
}

export default function Upload() {
  const [students, setStudents] = useState({});
  const [matric, setMatric] = useState([]);
  const [excluded, setExcluded] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [hidden, setHidden] = useState(false);
  const fileInputRef = useRef(null);
  const [status, setStatus] = React.useState({
    status: "",
    type: "",
    message: "",
  });
  const [errorText, setErrorText] = useState("");
  const [errorFile, setErrorFile] = useState("");

  const handleCopy = () => {
    const textToCopy = document.querySelector(".student-upload-text").innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        // console.error("Failed to copy: ", err);
      });
  };

  function handleChange(event) {
    const file = event.target.files[0];

    const fileCheck = file && checkFile(file, 1024);
    if (fileCheck === "size") {
      setErrorFile("File is too Large, Maximum of 1MB.");
    } else {
      setErrorFile("");
    }
    if (!file || fileCheck !== true) {
      setStudents({});
      setMatric([]);
      setExcluded([]);
      fileInputRef.current.value = null;
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const jsonData = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]]
      );

      const headers = ["Name", "Matric", "Department", "Level"];
      const students = {};
      let excluded = [];
      let matric = [];
      for (let k = 0; k < jsonData.length; k++) {
        let student = jsonData[k];
        const row = student["__rowNum__"] + 1;
        if (!student.hasOwnProperty("Matric")) {
          matric.push(row);
        } else {
          const key = extractIdnum(student["Matric"]);
          if (!key) {
            continue;
          }
          let exclude = false;
          for (let p = 0; p < headers.length; p++) {
            const header = headers[p];
            if (!student.hasOwnProperty(header)) {
              excluded.push(row);
              exclude = true;
              break;
            }
          }
          if (!exclude) {
            student = { ...student, Idnum: key };
            const stud = {};
            const studentKeys = Object.keys(student);
            for (let h = 0; h < studentKeys.length; h++) {
              const key = studentKeys[h];
              stud[key.toLowerCase()] = student[key];
            }
            students[key] = stud;
          }
        }
      }
      if (matric.length === 0 && excluded.length === 0) {
        setStudents(students);
      }
      setExcluded(excluded);
      setMatric(matric);
    };

    reader.readAsArrayBuffer(file);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      if (Object.keys(students).length !== 0) {
        setLoading(true);
        const send = await studentsUpload({
          students: JSON.stringify(students),
        });
        if (send === "Success") {
          setStatus({
            status: "success",
            type: "success",
            message: "Upload Successful",
          });
          setStudents({});
          setMatric([]);
          setExcluded([]);
          fileInputRef.current.value = null;
        } else if (send === "Corrupt Data") {
          setStatus({
            status: "data",
            type: "failed",
            message: "Corrupt Data",
          });
        } else if (send === "Exists") {
          setStatus({
            status: "exists",
            type: "failed",
            message: "Month Record Exists",
          });
        } else {
          setStatus({
            status: "error",
            type: "failed",
            message: "Upload Failed",
          });
        }
        setLoading(false);
      }
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Upload Failed",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    const textContainer = document.getElementsByClassName("truncate-text");
    if (textContainer.length === 0) {
      setHidden(false);
    }
    for (let k = 0; k < textContainer.length; k++) {
      const container = textContainer[k];
      setHidden(isEllipsisActive(container));

      container.addEventListener("resize", function () {
        setHidden(isEllipsisActive(container));
      });
    }

    function isEllipsisActive(element) {
      return (
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth
      );
    }
    document.title = "Students Upload";

    return () => {
      for (let k = 0; k < textContainer.length; k++) {
        const container = textContainer[k];
        container.removeEventListener("resize", function () {
          setHidden(isEllipsisActive(container));
        });
      }
    };
  }, [students, matric, excluded]);

  const valid = Object.keys(students).length;
  const invalid = matric.length + excluded.length;

  return (
    <>
      {errorText && (
        <div className="student-upload-viewer">
          <LiaTimesCircle
            onClick={() => {
              setErrorText("");
            }}
          />
          <div>
            {matric.length !== 0 && (
              <p>
                Missing <strong>Matric Number</strong> at Row
                {matric.length > 1 && "s"}: <span>{matric.join(", ")}</span>
              </p>
            )}
            {excluded.length !== 0 && (
              <p>
                Missing / Invalid <strong>Data</strong> at Row
                {excluded.length > 1 && "s"}: <span>{excluded.join(", ")}</span>
              </p>
            )}
            {invalid !== 0 && (
              <>
                <p>
                  <strong>
                    {invalid} Student{invalid > 1 && "s"}
                  </strong>{" "}
                  Informations are Invalid.
                </p>
                <p>Fix these issues to proceed with upload.</p>
              </>
            )}
            {valid !== 0 && invalid === 0 && (
              <>
                <p>
                  <strong>
                    {valid} Student{valid > 1 && "s"}
                  </strong>{" "}
                  Informations extracted successfully,
                </p>
                <p>Proceed with Upload?</p>
              </>
            )}
            <p>{errorFile}</p>
            {valid === 0 && invalid === 0 && <p>Choose A Valid Excel File.</p>}
          </div>
        </div>
      )}
      <div className="form-header confirm-form">
        <h1>Students Upload</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div>
            <div className="form-group">
              <label htmlFor="file">Excel File</label>
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleChange}
                id="file"
                name="file"
                ref={fileInputRef}
                disabled={loading}
                required
              />
            </div>
            <div className="student-upload-text">
              <span
                className={`student-upload-copy student-upload-view ${
                  hidden && "flicker"
                }`}
                title="View"
              >
                <FaEye
                  onClick={() => {
                    setErrorText("show");
                  }}
                />
              </span>
              <span
                className={`student-upload-copy ${hidden && "flicker"}`}
                title="Copy"
              >
                {!copied && <FaCopy onClick={handleCopy} />}
                {copied && (
                  <FaCheckDouble
                    style={{ fontSize: "0.9em", cursor: "default" }}
                  />
                )}
              </span>
              {matric.length !== 0 && (
                <p>
                  Missing <strong>Matric Number</strong> at Row
                  {matric.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{matric.join(", ")}</span>
                </p>
              )}
              {excluded.length !== 0 && (
                <p>
                  Missing / Invalid <strong>Data</strong> at Row
                  {excluded.length > 1 && "s"}:{" "}
                  <span className="truncate-text">{excluded.join(", ")}</span>
                </p>
              )}
              {invalid !== 0 && (
                <>
                  <p>
                    <strong>
                      {invalid} Student{invalid > 1 && "s"}
                    </strong>{" "}
                    Informations are Invalid.
                  </p>
                  <p>Fix these issues to proceed with upload.</p>
                </>
              )}
              {valid !== 0 && invalid === 0 && (
                <>
                  <p>
                    <strong>
                      {valid} Student{valid > 1 && "s"}
                    </strong>{" "}
                    Informations extracted successfully,
                  </p>
                  <p>Proceed with Upload?</p>
                </>
              )}
              <p>{errorFile}</p>
              {valid === 0 && invalid === 0 && (
                <>
                  <p>Choose A Valid Excel File.</p>
                  <a href="/A Valid Excel File.pdf" target="_blank">
                    What is A Valid Excel File?
                  </a>
                </>
              )}
              {hidden && (
                <p style={{ color: "darkred" }}>
                  <i>
                    Click the <strong>Copy</strong> or <strong>View</strong>{" "}
                    icon to access full <strong>Error Message</strong>.
                  </i>
                </p>
              )}
            </div>
          </div>
          {valid !== 0 && invalid === 0 && (
            <div className="form-group">
              <button type="submit" disabled={loading}>
                {loading ? <FaSpinner className="spinner" /> : "Upload"}
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
