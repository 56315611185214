import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend, Title } from "chart.js";
import {
  extractAttendanceDetails,
  getCentresAttendance,
  getStatistics,
} from "../AppManager";
import { nanoid } from "nanoid";

Chart.register(ArcElement, Tooltip, Legend, Title);

export default function Statistics() {
  const [chartData, setChartData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [stats, setStats] = useState({ total: 0, present: 0, absent: 0 });
  const [average, setAverage] = useState([]);

  useEffect(() => {
    getStatistics()
      .then((stats) => {
        const { total, present, absent } = extractAttendanceDetails(stats);
        setStats({ total, present, absent });
      })
      .catch((err) => setStats({ total: 1, present: 1, absent: 0 }));

    getCentresAttendance()
      .then((data) => {
        if (typeof data === "object") {
          const centres = Object.keys(data).sort();
          const output = [];
          for (let k = 0; k < centres.length; k++) {
            const centre = centres[k];
            if (Object.hasOwnProperty.call(data, centre)) {
              const present = data[centre];
              output.push({ centre, present });
            }
          }
          setAverage(output);
        }
      })
      .catch((error) => error);
    document.title = "Statistics";
  }, []);

  useEffect(() => {
    const Data = [
      {
        id: 1,
        numbers: stats.present,
      },
      {
        id: 2,
        numbers: stats.absent,
      },
    ];

    const chartDat = {
      labels: ["Present", "Absent"],
      datasets: [
        {
          label: "Statistics",
          data: Data.map((data) => data.numbers),
          backgroundColor: [
            // "#072A46",
            "#FFC436",
            "darkred",
          ],
          // borderColor: "black",
          // borderWidth: 2
        },
      ],
    };

    setChartData(chartDat);
  }, [stats]);

  const onHover = (event, chartElement) => {
    if (chartElement.length) {
      setActiveIndex(chartElement[0].index);
    } else {
      setActiveIndex(null);
    }
  };

  const customChartData = {
    ...chartData,
    datasets: chartData.datasets
      ? chartData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: dataset.backgroundColor.map((color, index) => {
            if (activeIndex !== null && index !== activeIndex) {
              return "rgba(0, 0, 0, 0.1)";
            }
            return color;
          }),
          borderColor: dataset.backgroundColor.map((color, index) => {
            if (activeIndex !== null && index !== activeIndex) {
              return "rgba(255, 255, 255, 0.1)";
            }
            return "white";
          }),
        }))
      : [],
  };

  const averageElement = average.map(({ centre, present }) => (
    <span key={nanoid()}>
      <span>●</span> {centre}: <strong>{present}</strong>
    </span>
  ));

  return (
    <>
      <div className="form-header">
        <h1>Statistics</h1>
      </div>
      <div className="form-content">
        <form className="stats-pie">
          <Pie
            data={customChartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                  labels: {
                    color: "#072A46",
                  },
                },
                title: {
                  display: true,
                  text: `Total Students: ${stats.total}`,
                  color: "#072A46",
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const value = context.raw;
                      const total = context.dataset.data.reduce(
                        (acc, curr) => acc + curr,
                        0
                      );
                      const percentage = ((value / total) * 100).toFixed(2);
                      return ` ${value} (${percentage}%)`;
                    },
                  },
                },
              },
              onHover: onHover,
            }}
          />
        </form>
        {averageElement.length !== 0 && (
          <span className="stats-breakdown">
            <button className="average-label">
              <img
                src="/images/icons/analysis.png"
                alt="Attendance Breakdown"
              />
            </button>
            <span className="average-data">
              <strong>Attendance Breakdown</strong>
              {averageElement}
            </span>
          </span>
        )}
      </div>
    </>
  );
}
