import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  document.title = "Page not Found";
  return (
    <section className="error">
      <img src="/images/svg/404.svg" alt="Page not Found" />
      <Link to={"/"} className="error-btn">
        Go Back Home
      </Link>
    </section>
  );
}

export default NotFound;
