import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { login } from "../Auth";

export default function Login() {
  const params = useSearchParams()[0];
  const next = params.get("next");
  const message = params.get("message");
  const [formData, setFormData] = useState({ password: "" });
  const [status, setStatus] = React.useState({
    status: "",
    type: "",
    message: message ? message : "",
  });
  const [loading, setLoading] = useState(false);
  const [showp, setShowp] = useState(false);

  const { handleSlide } = useOutletContext();
  const navigate = useNavigate();

  document.title = "Login";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((old) => ({ ...old, [name]: value }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const res = await login(formData);
      if (res === "Success") {
        setStatus({
          status: "success",
          type: "success",
          message: "Log-In Successful. Redirecting...",
        });
        setFormData({ password: "" });
        setTimeout(() => {
          if (next) {
            navigate(next);
          } else {
            navigate("/");
          }
          setLoading(false);
        }, 1000);
      } else if (res === "Incorrect") {
        setStatus({
          status: "Incorrect",
          type: "failed",
          message: "Incorrect Password",
        });
        setLoading(false);
      } else {
        setStatus({ status: "error", type: "failed", message: "Login Failed" });
        setLoading(false);
      }
    } catch (error) {
      setStatus({ status: "error", type: "failed", message: "Login Failed" });
      setLoading(false);
    }
  }

  const handleShowp = () => {
    setShowp((old) => !old);
  };

  return (
    <>
      <div className="form-header">
        <h1>Admin Login</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              disabled={loading}
              type={showp ? "text" : "password"}
              onChange={handleChange}
              value={formData.password}
              id="password"
              name="password"
              required
              placeholder="Password"
            />
            {!showp && <FaEye onClick={handleShowp} className="password-eye" />}
            {showp && (
              <FaEyeSlash onClick={handleShowp} className="password-eye" />
            )}
          </div>
          <div className="form-group">
            <label className="form-recovery" onClick={handleSlide}>
              Forgot Password?
            </label>
          </div>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? <FaSpinner className="spinner" /> : "Login"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
