import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCurrTimeInfo } from "../AppManager";
import { generalURL } from "../Auth";

export default function View() {
  const [member, setMember] = useState({
    name: "",
    department: "",
    level: "",
    image: "",
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { matric } = useParams();

  useEffect(() => {
    if (
      state &&
      state.permission &&
      state.member &&
      state.id &&
      state.member.idnum === state.id &&
      matric === state.id
    ) {
      setMember(state.member);
    } else {
      navigate("/view");
    }
    document.title = "View Student";
  }, [navigate, state, matric]);

  return (
    <>
      <div className="form-header confirm-form">
        <h1>View Student</h1>
      </div>
      <div className="form-content">
        <form>
          <div className="imgwrap">
            <img
              onError={(e) => {
                if (e.target.src !== "/user.png") {
                  e.target.onerror = null;
                  e.target.src = "/user.png";
                }
              }}
              className="img"
              src={`${generalURL}/images/students/${member.image}`}
              alt={member.name}
            />
          </div>
          <div className="form-text-group">
            <p className="form-text">
              <strong>{member.name}</strong>
            </p>
            <p className="form-text">{member.matric}</p>
            <p className="form-text">{member.department}</p>
            <p className="form-text">{member.level} Level</p>
            <p className="form-text">{member.attendance}</p>
            {member.hasOwnProperty(getCurrTimeInfo().today) && (
              <p className="form-text">{member[getCurrTimeInfo().today]}</p>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
