import React, { useEffect, useState } from "react";
import { getSession, updateSession } from "../AppManager";
import { FaChevronDown, FaSpinner } from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";

export default function Session() {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = React.useState({
    status: "",
    type: "",
    message: "",
  });
  const [formData, setFormData] = useState({
    session: "",
  });

  useEffect(() => {
    getSession()
      .then((session) => {
        if (session === "true" || session === "false") {
          setFormData({ session });
          setDisabled(false);
        }
      })
      .catch((err) => err);
    document.title = "Update Session";
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData({ [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      const send = await updateSession(formData);
      if (send === "Updated") {
        setStatus({
          status: "success",
          type: "success",
          message: "Session Updated",
        });
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
      }
      setLoading(false);
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Upload Failed",
      });
      setLoading(false);
    }
  }

  return (
    <>
      <div className="form-header">
        <h1>Update Session</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div>
            <div className="form-group">
              <label htmlFor="session">Active Session?</label>
              <select
                disabled={loading || disabled}
                id="session"
                onChange={handleChange}
                value={formData.session}
                name="session"
                required
                autoComplete="off"
              >
                <option disabled value="">
                  Choose one
                </option>
                <option value="true">True</option>
                <option value="false">False</option>
              </select>
              <FaChevronDown className="select-icon" />
            </div>
            <div className="form-group">
              <button type="submit" disabled={loading || disabled}>
                {loading ? <FaSpinner className="spinner" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
