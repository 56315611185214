import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <section className="error">
      <img src="/images/svg/error.svg" alt="An Error Occured" />
      <Link to={"/"} className="error-btn">
        Go Back Home
      </Link>
    </section>
  );
}

export default Error;
