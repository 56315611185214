import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Carousel } from "react-bootstrap";
import {
  capitalizeEachWord,
  debounce,
  getCarouselImages,
  getCentres,
  getDepartments,
} from "../AppManager";
import Offline from "./Offline";
import { forgottenPassword, getCentreToken } from "../Auth";
import { LiaTimesCircle } from "react-icons/lia";
import { FaSpinner } from "react-icons/fa6";
import { nanoid } from "nanoid";

function Layout() {
  const { pathname } = useLocation();
  const [forgotData, setForgotData] = useState({ email: "" });
  const [slide, setSlide] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [images, setImages] = useState(["img1.png", "img2.png", "img3.png"]);
  const [centres, setCentres] = useState({});
  const [off, setOff] = useState(false);
  const [status, setStatus] = React.useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCarouselImages()
      .then((images) => setImages(images))
      .catch((err) => err);

    getCentres()
      .then((data) => {
        let cents = {};
        for (const cent in data) {
          if (Object.hasOwnProperty.call(data, cent)) {
            cents[cent] = data[cent];
          }
        }
        setCentres(cents);
      })
      .catch((err) => err);

    getDepartments()
      .then((data) => {
        const depts = [];
        for (const dept in data) {
          if (Object.hasOwnProperty.call(data, dept)) {
            const department = data[dept];
            depts.push(department);
          }
        }
        setDepartments(depts);
      })
      .catch((err) => err);
  }, []);

  const handleForgotChange = (e) => {
    const { name, value } = e.target;
    setForgotData((old) => ({ ...old, [name]: value }));
  };

  const handleForgotSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setStatus({ status: "", type: "", message: "" });
      const send = await forgottenPassword(forgotData);
      if (send === "Success") {
        setStatus({
          status: "success",
          type: "success",
          message: "Password Reset Link Sent",
        });
        setForgotData({ email: "" });
      } else if (send === "Incorrect") {
        setStatus({
          status: "failed",
          type: "failed",
          message: "Incorrect E-Mail Supplied",
        });
      } else if (send === "Not Found") {
        setStatus({
          status: "failed",
          type: "failed",
          message: "Request Failed",
        });
      } else if (send === "Exists") {
        setStatus({
          status: "Exists",
          type: "failed",
          message: "Previous Reset Link Unused",
        });
        setForgotData({ email: "" });
      } else {
        setStatus({
          status: "failed",
          type: "failed",
          message: "Request Failed",
        });
      }
      setLoading(false);
    } catch (error) {
      setStatus({
        status: "failed",
        type: "failed",
        message: "Request Failed",
      });
      setLoading(false);
    }
  };

  const handleSlide = () => {
    setSlide((old) => {
      const stats = !old;
      if (stats) {
        document
          .querySelector(".form-panel.one")
          .scrollIntoView({ block: "start" });
      }
      return stats;
    });
  };

  useEffect(() => {
    const formone = document.getElementsByClassName("form-panel one")[0];

    function height() {
      const heightPx = formone.clientHeight;
      const clicker = document.querySelector(".nav-clicker");
      if (clicker) clicker.style.height = heightPx + "px";
    }

    const debouncedHeight = debounce(height, 100);

    height();
    const resizeObserver = new ResizeObserver(debouncedHeight);
    resizeObserver.observe(formone);

    return () => {
      resizeObserver.disconnect();
      clearTimeout(debouncedHeight.timeout);
    };
  }, []);

  useEffect(() => {
    document
      .querySelector(".form-panel.one")
      .scrollIntoView({ block: "start" });
  }, [pathname]);

  // useEffect(() => {
  //   function changeStatus() {
  //     if (!navigator.onLine) {
  //       setOff(true);
  //     }
  //   }
  //   if (!navigator.onLine) {
  //     setOff(true);
  //   }
  //   window.addEventListener("offline", changeStatus);
  //   return () => {
  //     window.removeEventListener("offline", changeStatus);
  //   };
  // }, []);

  useEffect(() => {
    function tabClick(event) {
      if (event.key === "Tab" || event.keyCode === 9) {
        event.preventDefault();
      }
    }
    document.addEventListener("keydown", tabClick);
    return () => {
      document.removeEventListener("keydown", tabClick);
    };
  }, []);

  useEffect(() => {
    let refetch = "";
    refetch = setInterval(() => {
      getDepartments()
        .then((data) => {
          const depts = [];
          for (const dept in data) {
            if (Object.hasOwnProperty.call(data, dept)) {
              const department = data[dept];
              depts.push(department);
            }
          }
          JSON.stringify(depts) !== JSON.stringify(departments) &&
            setDepartments(depts);
        })
        .catch((err) => err);

      getCentres()
        .then((data) => {
          let cents = {};
          for (const cent in data) {
            if (Object.hasOwnProperty.call(data, cent)) {
              cents[cent] = data[cent];
            }
          }
          JSON.stringify(cents) !== JSON.stringify(centres) &&
            setCentres(cents);
        })
        .catch((err) => err);
    }, 5000);

    return () => {
      clearInterval(refetch);
    };
  }, [departments, centres]);

  const carouselImages = images.map((image, index) => (
    <Carousel.Item className="w-100 h-100" key={nanoid()}>
      <img
        className="w-100 h-100 object-fit-cover"
        src={`/images/carousel/${image}`}
        alt={`Slide ${index + 1}`}
      />
    </Carousel.Item>
  ));

  return (
    <div className="d-flex flex-column w-100 h-100 position-relative">
      <main className="main-wrapper w-100 h-100 position-relative d-flex align-items-center justify-content-center">
        <Carousel
          className="w-100 h-100 position-absolute top-0 z-n1"
          controls={false}
          indicators={false}
          fade
          interval={6000}
        >
          {carouselImages}
        </Carousel>
        <div className="outlet">
          <div
            className="nav-clicker"
            onClick={
              pathname.indexOf("/login") !== -1 ||
              pathname.indexOf("/reset") !== -1
                ? null
                : !slide
                ? handleSlide
                : null
            }
          >
            <span></span>
            <span></span>
          </div>
          <div className={slide ? "form-panel one hidden" : "form-panel one"}>
            <span className="ruc-logo">
              <img src="/images/logo.png" alt="RUC Chapel of Power Logo" />
            </span>
            <Outlet context={{ handleSlide, departments, centres }} />
            {off &&
              pathname !== "/login" &&
              pathname.indexOf("/reset") === -1 && (
                <Offline
                  close={() => {
                    setOff(false);
                  }}
                />
              )}
          </div>
          <div className={slide ? "form-panel two active" : "form-panel two"}>
            <div
              className={slide ? "form-toggle visible" : "form-toggle"}
              onClick={handleSlide}
            ></div>
            <h6
              style={{
                color: "white",
                position: "absolute",
                top: "16px",
                fontSize: "12px",
              }}
            >
              {pathname.indexOf("/login") === -1 &&
                capitalizeEachWord(getCentreToken().centre)}
            </h6>

            {pathname.indexOf("/login") !== -1 && (
              <div className="form-header">
                <h1>Forgot Password</h1>
              </div>
            )}
            {pathname.indexOf("/login") !== -1 ? (
              <div className="form-content">
                <form onSubmit={handleForgotSubmit}>
                  {status.message !== "" && (
                    <p className="status-wrapper" id="status-wrapper">
                      <span className={`status-message ${status.type}`}>
                        {status.message}
                      </span>
                      <LiaTimesCircle
                        className="status-close"
                        onClick={() => {
                          setStatus({ status: "", type: "", message: "" });
                        }}
                      />
                    </p>
                  )}
                  <div className="form-group">
                    <label htmlFor="email">E-Mail</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={forgotData.email}
                      onChange={handleForgotChange}
                      maxLength={50}
                      required
                      placeholder="E-Mail"
                      disabled={loading}
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" disabled={loading}>
                      {loading ? (
                        <FaSpinner className="spinner" />
                      ) : (
                        "Send Link"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              !off &&
              pathname.indexOf("/reset") === -1 && (
                <Navbar slide={slide} handleSlide={handleSlide} />
              )
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
