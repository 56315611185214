import Swal from "sweetalert2";
import { axios, getCentreToken } from "./Auth";

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const fullDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function getCurrTimeInfo() {
  const date = new Date();
  const today = `${days[date.getDay()]}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getFullYear()}`;
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  const options24 = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hourCycle: "h23",
  };
  const currentTime = new Intl.DateTimeFormat("en-US", options).format(date);
  const currentTime24 = new Intl.DateTimeFormat("en-US", options24).format(
    date
  );
  const currTime = currentTime.slice(0, 8);
  const currTime24 = currentTime24.slice(0, 8);
  const currMonth = `${months[
    date.getMonth()
  ].toLowerCase()}${date.getFullYear()}`;
  const upperCurrMonth = `${months[date.getMonth()]} ${date.getFullYear()}`;
  const currentMonth = date.toLocaleDateString("en-US", {
    month: "short",
    year: "numeric",
  });
  return {
    currTime,
    currTime24,
    currMonth,
    currentMonth,
    upperCurrMonth,
    today,
    options,
    options24,
  };
}

export function calcDisplayNo(
  width,
  height,
  pagePadMarginWidth,
  pagePadMarginHeight
) {
  const screenWidth = window.screen.width - pagePadMarginWidth;
  const screenHeight = window.screen.height - pagePadMarginHeight;
  const maxColumn = Math.floor(screenWidth / width);
  const maxRow = Math.floor(screenHeight / height) + 1;
  const maxItems = maxColumn * maxRow;
  return maxItems > 5 ? maxItems : 5;
}

export function capitalizeEachWord(str) {
  if (!str) return "";

  let wordsArray = str.split(/([-\s])/);

  let capitalizedArray = wordsArray.map((word) => {
    if (word === " " || word === "-") {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalizedArray.join("");
}

export function getIntValueOrString(inputString) {
  if (/^\d+$/.test(inputString)) {
    return parseInt(inputString, 10).toString();
  } else {
    return inputString.toUpperCase();
  }
}

export function stripSpecialChar(string) {
  if (string) {
    const specialChars = ["\\", "/", "*", ":", "?", '"', "<", ">", "|", "&"];
    let newString = "";
    for (let k = 0; k < string.length; k++) {
      if (!specialChars.includes(string[k])) {
        newString += string[k];
      }
    }
    return newString;
  }
}

export function checkExtension(string) {
  const extensions = ["jpeg", "jpg", "png", "heic"];
  const ext = string.slice(string.lastIndexOf(".") + 1).toLowerCase();
  if (extensions.includes(ext)) {
    return true;
  }
  return false;
}

export function checkImage(files, maxSize, setError, setStatus) {
  setError && setError(true);
  if (files.length > 5) {
    setStatus &&
      setStatus({
        status: "failed",
        type: "failed",
        message: "Maximum of 5 Images",
      });
    return "length";
  }
  const maximum = 1024 * maxSize;
  for (let e = 0; e < files.length; e++) {
    const file = files[e];
    if (file.size <= maximum && checkExtension(file.name)) {
      setError && setError(false);
      setStatus && setStatus({ status: "", type: "", message: "" });
      return true;
    } else {
      if (file.size > maximum) {
        setStatus &&
          setStatus({
            status: "failed",
            type: "failed",
            message: "Maximum File Size Exceeded",
          });
        return "size";
      } else if (!checkExtension(file.name)) {
        setStatus &&
          setStatus({
            status: "failed",
            type: "failed",
            message: "Invalid File Format",
          });
        return "format";
      }
    }
  }
}

export function cleanName(str) {
  if (str) {
    let string = capitalizeEachWord(str);
    let newString = "";
    for (let k = 0; k < string.length; k++) {
      if (
        /[A-Z]/.test(string[k]) ||
        /[a-z]/.test(string[k]) ||
        string[k] === "-" ||
        string[k] === " "
      ) {
        newString += string[k];
      }
    }
    return newString;
  }
}

export function cleanMatric(string) {
  if (string) {
    let newString = "";
    for (let k = 0; k < string.length; k++) {
      if (
        /[A-Z]/.test(string[k]) ||
        /[a-z]/.test(string[k]) ||
        /\d/.test(string[k]) ||
        string[k] === "-" ||
        string[k] === "/"
      ) {
        newString += string[k].toUpperCase();
      }
    }
    return newString.trim();
  }
}

export function stripSpace(string) {
  if (string) {
    let newString = "";
    for (let k = 0; k < string.length; k++) {
      if (string[k] !== " ") {
        newString += string[k];
      }
    }
    return newString;
  }
}

export function sortMonths(input) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const lowerMonths = months.map((month) => month.toLowerCase());
  const sortedMonths = [];
  let powers = [];
  const powerMonths = {};
  for (let i = 0; i < input.length; i++) {
    const element = input[i].toLowerCase();
    const split = element.match(/[a-zA-Z]+|[0-9]+/g);
    const index = lowerMonths.indexOf(split[0]);
    const power = index / 10 + parseInt(split[1]);
    powerMonths[power] = `${months[index]} ${split[1]}`;
  }
  for (const key in powerMonths) {
    powers.push(key);
  }
  powers = powers.sort();
  for (let m = 0; m < powers.length; m++) {
    const element = powers[m];
    sortedMonths.unshift(powerMonths[element]);
  }
  return sortedMonths;
}

function compareByProperty(a, b, property) {
  return a[property].localeCompare(b[property]);
}

export function sortByProperty(array, property) {
  return array.sort((a, b) => compareByProperty(a, b, property));
}

export function passwordStrength(password) {
  let passwordStat = {
    strength: "",
    color: "",
    upper: false,
    lower: false,
    special: false,
    length: false,
    number: false,
  };
  let percentage = 0;
  if (password.length >= 6) {
    passwordStat.length = true;
    percentage += 20;
  }
  if (/[A-Z]/.test(password)) {
    passwordStat.upper = true;
    percentage += 20;
  }
  if (/[a-z]/.test(password)) {
    passwordStat.lower = true;
    percentage += 20;
  }
  if (/\d/.test(password)) {
    passwordStat.number = true;
    percentage += 20;
  }
  // eslint-disable-next-line
  if (/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
    passwordStat.special = true;
    percentage += 20;
  }

  if (percentage === 20 || percentage === 0) {
    passwordStat.color = "red";
  }
  if (percentage === 40) {
    passwordStat.color = "orange";
  }
  if (percentage === 60) {
    passwordStat.color = "yellowgreen";
  }
  if (percentage === 80) {
    passwordStat.color = "darkgreen";
  }
  if (percentage === 100) {
    passwordStat.color = "darkblue";
  }
  return { ...passwordStat, strength: percentage + "%" };
}

export function showSwal(icon, title, text) {
  Swal.fire({
    icon: icon,
    title: title,
    text: text,
    confirmButtonText: "OK",
  });
}

export async function userTimeChecker() {
  // Checks if User time is correct
  const response = await fetch("https://worldtimeapi.org/api/ip");
  const data = await response.json();
  const apiTime = new Date(data.datetime).getTime();
  const clientTime = Date.now();

  const timeDifference = Math.abs(clientTime - apiTime);
  const maxAllowedDifference = 300000; // e.g., 5 minutes in milliseconds

  if (timeDifference > maxAllowedDifference) {
    return false;
  }
  return true;
}

export function extractIdnum(string) {
  const i = string.lastIndexOf("/") + 1;
  if (i === 0) {
    if (string.length > 7) {
      return string.slice(string.length - 7);
    } else {
      return string;
    }
  } else {
    return string.slice(i);
  }
}

export function extractAttendanceDetails(attendanceString) {
  // Regular expression to match the percentage and the numbers in the format "percentage (present / total)"
  const regex = /([\d.]+)%\s*\((\d+)\s*\/\s*(\d+)\)/;
  const match = attendanceString.match(regex);

  if (match) {
    const percentage = parseFloat(match[1]);
    const present = parseInt(match[2], 10);
    const total = parseInt(match[3], 10);
    const absent = total - present;

    return { percentage, total, present, absent };
  } else {
    throw new Error("Invalid attendance string format");
  }
}

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export function filterObject(obj, predicate) {
  return Object.keys(obj).reduce((result, key) => {
    if (predicate(obj[key])) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export function getDepartmentCode(department, departments) {
  const dept = filterObject(
    departments,
    (value) => value.programme === department
  );
  return dept[Object.keys(dept)[0]].code;
}

// ================================================================================

// PHP

// ================================================================================
export async function addStudent(formData) {
  try {
    const tokenData = getCentreToken();
    const { centre, token } = tokenData;

    const data = {
      ...formData,
      auth_centre: centre,
      auth_token: token,
      add_student: "smdj ewx92",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/registration.php";
    const send = await axios.post(url, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function updateStudent(formData) {
  try {
    const data = { ...formData, update_student: "leerkjfek" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function addDepartment(formData) {
  try {
    const data = {
      ...formData,
      add_department: "mowf 34 msek",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function addCentre(formData) {
  try {
    const data = {
      ...formData,
      add_centre: "wnjkfenkw32u",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function eventTime(formData) {
  try {
    const data = {
      ...formData,
      event_time: "mwmowewn43",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function updateDepartment(formData) {
  try {
    const data = { ...formData, update_department: "mweof onwekrm" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getSession() {
  try {
    const data = { get_session: "lkwd2 l3k " };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    return JSON.stringify(send.data);
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function updateSession(formData) {
  try {
    const data = { ...formData, update_session: "jskc kejo" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function deleteStudent(matric) {
  try {
    const data = { matric, delete_student: "mklhcdgfk" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/delete.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function deleteDepartment(code) {
  try {
    const data = { code, delete_department: "dm39rf 33s" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/delete.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function deleteCentre(centre) {
  try {
    const data = { centre, delete_centre: "nme8ncjwxd snj" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/delete.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getNowStudent(idnum) {
  try {
    const data = { idnum, get_now_student: "jknkmomoi" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    return res;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function studentSearch(formData) {
  try {
    const data = { ...formData, student_search: "nodifneow3c3 k" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    if (typeof res === "object") {
      return res;
    } else {
      return [];
    }
  } catch (error) {
    return [];
    // return error;
  }
}

export async function getStudent(record, idnum) {
  try {
    const data = { record, idnum, get_student: "nwkjnerkjwn" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    return res;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getStudentAverage(table, idnum) {
  try {
    const data = { table, idnum, get_student_average: "jee3 i4u s3i" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    return res;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getMonthStatistics(month) {
  try {
    const data = { month, month_statistics: "mjljtfyg" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    return res;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getMonths() {
  try {
    const data = { get_months: "fljtyljtrjnkj" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    if (typeof res === "object") {
      return res;
    } else {
      return "Failed";
    }
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getCentres() {
  try {
    const data = { get_centres: "ejnfkjer" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;

    if (typeof res === "object") {
      return res;
    } else {
      return {};
    }
  } catch (error) {
    return {};
    // return error;
  }
}

export async function getDepartments() {
  try {
    const data = { get_departments: "mdjenrfkj" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    if (typeof res === "object") {
      return res;
    } else {
      return "Failed";
    }
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getCentresAttendance() {
  try {
    const data = { get_centres_attendance: "mkllmlkfdl" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    if (typeof res === "object") {
      return res;
    } else {
      return "Failed";
    }
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function generateAttendance(month) {
  try {
    const data = {
      generate_attendance: "j hkjhfnjh",
      month,
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/download.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function generateAnalysis(month) {
  try {
    const data = {
      generate_analysis: "mdu i438 9js",
      month,
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/analysis.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function generateAverageAttendance(table, tableName) {
  try {
    const data = {
      generate_average_attendance: " eijni2 i2",
      table,
      tableName,
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/downloadAverage.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function updateCentreDepartments(formData) {
  try {
    const data = {
      centre_departments: "jd28deiwnkjw",
      ...formData,
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function studentsUpload(formData) {
  try {
    const data = {
      students_upload: "md93un 3fekl",
      ...formData,
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function createAverage(formData) {
  try {
    const data = {
      ...formData,
      create_average: "mi3x3 fre ekr3",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/update.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function tableExists(table) {
  try {
    const data = {
      table,
      table_exists: "dm43iocjt9o s",
    };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    return send.data;
  } catch (error) {
    return false;
    // return error;
  }
}

export async function getStatistics() {
  try {
    const data = { get_statistics: "jnkhjuyjk" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    return res;
  } catch (error) {
    return "Failed";
    // return error;
  }
}

export async function getCarouselImages() {
  try {
    const data = { get_carousel_images: "m92m32 ew98u" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    if (typeof res === "object" && res.length !== 0) {
      return res;
    }
    return ["img1.png", "img2.png", "img3.png"];
  } catch (error) {
    return ["img1.png", "img2.png", "img3.png"];
    // return error;
  }
}

export async function getServerTime() {
  try {
    const data = { get_time: "mx932hund3" };
    let formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    const url = "php/read.php";
    const send = await axios.post(url, formdata);
    const res = send.data;
    return res;
  } catch (error) {
    return false;
    // return error;
  }
}
