import React, { useEffect, useState } from "react";
import { LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { deleteCentre, showSwal, stripSpace } from "../AppManager";
import { FaSpinner } from "react-icons/fa6";

export default function DeleteCentre() {
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({ key: "" });
  const [keyword, setKeyword] = useState("loading...");
  const navigate = useNavigate();
  const { centre } = useParams();
  const { centres } = useOutletContext();

  useEffect(() => {
    const exists = Object.keys(centres).filter(
      (cent) => centre.toLowerCase() === cent.toLowerCase()
    );
    if (exists.length === 0) {
      navigate("/centres");
    }
  }, [centres, centre, navigate]);

  useEffect(() => {
    const trimname = stripSpace(centre);
    setKeyword(
      trimname.length >= 10
        ? trimname.slice(0, 10)
        : trimname.padEnd("10", nanoid(10 - trimname.length))
    );
    document.title = "Delete Centre";
  }, [centre]);

  function handleChange(e) {
    const { name, value } = e.target;
    setDisabled(value !== keyword);
    setFormData((oldData) => ({ ...oldData, [name]: value.slice(0, 10) }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStatus({ status: "", type: "", message: "" });
    try {
      setLoading(true);
      if (formData.key === keyword) {
        const res = await deleteCentre(centre);
        if (res === "Deleted") {
          navigate("/centres", { replace: true });
          showSwal("success", "Centre Deleted");
        } else if (res === "Not Found") {
          showSwal("error", "Centre not Found");
          navigate("/centres", { replace: true });
        } else {
          setTimeout(() => {
            setStatus({
              status: "error",
              type: "failed",
              message: "Delete Failed",
            });
            setLoading(false);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "error",
            message: "Incorrect Key",
          });
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Delete Failed",
        });
        setLoading(false);
      }, 1000);
    }
  }

  return (
    <>
      <div className="form-header">
        <h1>Delete Centre</h1>
      </div>
      <div className="form-content">
        <form onSubmit={handleSubmit}>
          {status.message !== "" && (
            <p className="status-wrapper" id="status-wrapper">
              <span className={`status-message ${status.type}`}>
                {status.message}
              </span>
              <LiaTimesCircle
                className="status-close"
                onClick={() => {
                  setStatus({ status: "", type: "", message: "" });
                }}
              />
            </p>
          )}
          <div className="delete-message">
            <p>
              Type <strong>{keyword}</strong> below to confirm delete
            </p>
            <i>
              <strong>Note:</strong> This action is irreversible and cannot be
              undone.
            </i>
          </div>
          <div className="form-group">
            <label htmlFor="key">Key</label>
            <input
              disabled={loading}
              type="text"
              onChange={handleChange}
              value={formData.idnum}
              id="key"
              name="key"
              required
              placeholder={keyword}
              minLength={10}
              maxLength={10}
            />
          </div>
          <div className="form-group">
            <button disabled={disabled || loading || keyword === "loading..."}>
              {loading ? <FaSpinner className="spinner" /> : "Confirm"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
